* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Bree Serif", serif;
    font-weight: 400;
    font-style: normal;
}

.limita-container {
    width: 100%;
    max-width: 1024px;
}