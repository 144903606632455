.container-whats {
    width: 50px;
    bottom: 160px;
    right: 10px;
    position: fixed;
    z-index: 5;
}

.container-whats .img {
    width: 100%;
    height: 50px;
    color: #fff;
    background-color: #52da40;
    border-radius: 40%;
    padding: 10px;
    box-shadow: 2px 2px 2px #c0bdbd
}

@media screen and (width:500px){
    .container-whats {
        bottom: 200px;
    }
}