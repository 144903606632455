#footer {
    background-color: #6F4E37;
    color: #F5F5DC;
    width: 100%;
    padding: 15px 30px;
    

    display: flex;
    margin-top: 50px;
    position: relative;
}

.box-redesocial, .box-frase {
    display: flex;
    align-items: center;
}

.box-info {
    flex-direction:column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.box-redesocial {
    flex-direction: row;
    gap: 15px;
}

.box-frase img {
    width: 110px;
}

.box-frase {
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 10px;
}



.box-info .links-info {
    text-decoration: none;
    color: #F5F5DC;
}

.limita-footer {
    width: 100%;
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 10px;
}

#footer .box-redesocial .img {
    width: 25px;
    height: 25px;
    border-radius: 30%;
    justify-content: center;
    align-items: center;
}

#footer .box-redesocial .link-redesocial {
    color:#F5F5DC ;
}

@media screen and (max-width:600px) {
    .limita-footer {
        display: flex;
        gap: 20px;
        margin-bottom: 85px;
        padding-bottom: 20px;

    }

    .box-info {
        width: 50%;
        gap: 10px;
    }

    .box-info .politica {
        font-size: 15px ;
    }

    .box-frase {
        width: 50%;
        align-items: center;
        justify-content: center;
    }

    .box-frase img {
        max-width: 100%;
        height: 100%;
    }

    .box-redesocial {
        display: none;
    }

    .solto-mobile {
        border-top: 1px solid #F5F5DC;
        position: absolute;
        bottom: 10%;
        right: 10%;
        left: 10%;
    }
}

@media screen and (max-width:380px) {
    .box-info .politica {
        font-size: 13px ;
    }
}