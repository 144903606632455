@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');


.main {
    background-color: #808080;
    font-family: "PT Sans Narrow", sans-serif;
    position: relative;
}

/*BENEFICIOS*/
.container-box {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    padding: 5px;

    width: 100%;
    max-width: 1200px;
}

.container-box .box {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 48%;
    padding: 10px;
}

.container-box .box .limite-img {
    width: 300px;
    height: 150px;
}

.container-box .box img {
    width: 100%;
    height: 100%;
    border-radius: 10%;
    object-fit: cover;
    object-position:top;
    box-shadow: 0 0 8px 3px #c0b7b7;
}

.beneficios h2 {
    text-align: center;
    margin: 35px 0;
}

.box p {
    display: flex;
    flex-direction: column;
    width: 700px;
}

.titulo_beneficio {
    margin-bottom: 10px;
    position: relative;
    width: 80%;
}

.titulo_beneficio::before {
    content: "";
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #808080, #6F4E37);

    position: absolute;
    bottom: 0;
    left: 0;
}

@media screen and (min-width:760px) {
    .container-box .box-inverso {
        flex-direction: row-reverse;
    }
}

@media screen and (max-width:760px) {
    .container-box .box {
        max-width: 100%;
    }
}

/*BENEFICIOS DO CAFE*/

.container-cafe {
    width: 100%;
    padding: 5px 15px;

}

.container-cafe div {
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
}

.container-cafe div .acordion {
    box-shadow: 0 0 15px 5px #808080;
    border-radius: 20px;
    margin-bottom: 20px;
}

.container-cafe div .acordion .caixa-acordion {
    border-radius: 20px;
}

.acordion h3 {
    margin-bottom: 20px;
    font-size: 23px;
    
}