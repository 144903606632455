@media screen and (min-width:700px) {
    .carousel-inner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .carousel-item {
        height: 100%;
        width: 100%;
    }

    .carousel-inner {
        height: 100vh;
    }

    .carrosel {
        margin-top:30px;
    }
}

@media screen and (max-width:500px) {
    .carrosel {
        margin-top: 50px;
    }
}